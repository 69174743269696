import {
  CreateAIGenerationInput,
  GenerateCurriculumWithAIButtonMutation,
} from "@/content/ai/button/__generated__/GenerateCurriculumWithAIButtonMutation.graphql"
import CurriculumGenerationReferenceMeter from "@/content/ai/CurriculumGenerationReferenceMeter"
import GenerateCurriculumWithAIForm from "@/content/ai/GenerateCurriculumWithAIForm"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useFormStore } from "@/core/form/store/FormStore"
import { GlobalID } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import AIButton from "@components/ai/AIButton"
import { MediaResult } from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import { DiscoModal } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import useDisclosure from "@utils/hook/useDisclosure"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { graphql } from "relay-runtime"

interface Props extends TestIDProps {
  curriculumId: GlobalID
  children: OverridableDiscoButtonChildren
  onSuccess?: () => void
}

export type GenerateCurriculumWithAIFormState = CreateAIGenerationInput & {
  assetEmbeddingSourceIds: GlobalID[]
  contentEmbeddingSourceIds: GlobalID[]
  trainingDataEmbeddingSourceIds: GlobalID[]
  uploadedAssets: MediaResult[]
  referenceMeter: number
}

function GenerateCurriculumWithAIButton({
  curriculumId,
  children,
  onSuccess,
  testid = "GenerateCurriculumAIButton",
}: Props) {
  const activeProduct = useActiveProduct()
  const classes = useStyles()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const form = useFormStore<
    GenerateCurriculumWithAIButtonMutation,
    GenerateCurriculumWithAIFormState
  >(
    graphql`
      mutation GenerateCurriculumWithAIButtonMutation($input: CreateAIGenerationInput!) {
        response: createAIGeneration(input: $input) {
          node {
            status
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      prompt: "",
      referenceUrl: "",
      referenceEmbeddingSourceIds: [],
      entityId: curriculumId,
      productId: activeProduct?.id || null,
      assetEmbeddingSourceIds: [],
      contentEmbeddingSourceIds: [],
      trainingDataEmbeddingSourceIds: [],
      uploadedAssetIds: [],
      referenceMeter: 0,
      uploadedAssets: [],
      searchWeb: true,
    }
  )

  return (
    <>
      <OverridableDiscoButton testid={testid} onClick={onOpen}>
        {children}
      </OverridableDiscoButton>
      <DiscoModal
        testid={testid}
        modalContentLabel={"Curriculum Generation"}
        title={"Generate Curriculum with AI"}
        minWidth={"720px"}
        classes={{
          footer: classes.modalFooter,
          leftSideFooter: classes.modalLeftSideFooter,
        }}
        isOpen={isOpen}
        onClose={onClose}
        body={<GenerateCurriculumWithAIForm form={form} />}
        leftSideFooter={
          <CurriculumGenerationReferenceMeter value={form.state.referenceMeter} />
        }
        buttons={
          <AIButton disabled={!form.state.prompt} onClick={handleSubmit}>
            {"Generate"}
          </AIButton>
        }
      />
    </>
  )

  async function handleSubmit() {
    const {
      contentEmbeddingSourceIds,
      assetEmbeddingSourceIds,
      trainingDataEmbeddingSourceIds,
    } = form.state

    const { didSave } = await form.submit({
      prompt: form.state.prompt,
      entityId: form.state.entityId,
      productId: form.state.productId,
      uploadedAssetIds: form.state.uploadedAssetIds,
      referenceUrl: form.state.referenceUrl,
      referenceEmbeddingSourceIds: [
        ...contentEmbeddingSourceIds,
        ...assetEmbeddingSourceIds,
        ...trainingDataEmbeddingSourceIds,
      ],
      searchWeb: form.state.searchWeb,
    })

    if (!didSave) return

    onSuccess?.()
    onClose()
  }
}

const useStyles = makeUseStyles((theme) => ({
  modalFooter: {
    gap: theme.spacing(1.5),
  },
  modalLeftSideFooter: {
    width: "100%",
  },
}))

export default observer(GenerateCurriculumWithAIButton)
